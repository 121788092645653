import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import { FAQJsonLd } from 'gatsby-plugin-next-seo'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql, StaticQuery } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from "@contentful/rich-text-types"
import casinolistStyle from "../components/Casinolist.module.css"

class Kasinot extends React.Component {
  componentDidMount() {
    var links = document.links;
    for (var i = 0, linksLength = links.length; i < linksLength; i++) {
        if (links[i].hostname != window.location.hostname) {
            links[i].target = '_blank';
            links[i].rel = 'noopener';
        }
    }
		var talletus = document.getElementsByClassName("talletus");
		var o;
		for (o = 0; o < talletus.length; o++) {
			var trustly = "<img src='/images/maksu/trustly.webp' width='75' height='50' title='Trustly' alt='trustly' class='lazyload'/>"
			var perinteiset = "<img src='/images/maksu/perinteiset.webp' width='75' height='50' title='Perinteiset maksutavat' alt='perinteiset' class='lazyload'/>"
			var brite = "<img src='/images/maksu/brite.webp' width='75' height='50' alt='brite' title='Brite' class='lazyload'/>"
			var zimpler = "<img src='/images/maksu/zimpler.webp' width='75' height='50' title='Zimpler' alt='zimpler' class='lazyload'/>"
			var str = talletus[o].innerHTML;

			var res = str.replace("Trustly", trustly).replace("Brite", brite).replace("Perinteiset", perinteiset).replace("Zimpler", zimpler);
			talletus[o].innerHTML = res;
		}

    var lisenssi = document.getElementsByClassName("lisenssi");
		var o;
		for (o = 0; o < lisenssi.length; o++) {
			var malta = "<img src='/images/lisenssi/mga.webp' width='75' height='75' title='Maltan lisenssi' alt='Maltan lisenssi' class='lazyload'/>"
			var viro = "<img src='/images/lisenssi/maksu-tolliamet.webp' width='75' height='75' title='Viron lisenssi' alt='Viron lisenssi' class='lazyload'/>"
      var curacao = "<img src='/images/lisenssi/curacao.webp' width='75' height='75' title='Curacaon lisenssi' alt='Curacaon lisenssi' class='lazyload'/>"
      var antillephone = "<img src='/images/lisenssi/antillephone.webp' width='75' height='75' title='Curacaon lisenssi / Antillephone' alt='Curacaon lisenssi / Antillephone' class='lazyload'/>"
			var str = lisenssi[o].innerHTML;

			var res = str.replace("Malta", malta).replace("Viro", viro).replace("Curacao", curacao).replace("Antillephone", antillephone);
			lisenssi[o].innerHTML = res;
		}

		
  }
  render() {
  return (
    <StaticQuery
      query = {graphql`
      query {
        contentfulMoneysivujenOsiot(slug: {eq: "nettikasinot"}) {
          slug
          sivu
          ylosanTeksti {
            json
          }
          kasinolista {
            kasinonNimi
            kasinonListanimi
            avausVuosi
            lause1
            ilmaiskierrokset
            rahansiirto
            lisenssi
            logonTiedostonimi
            afflink
            kasinonLisatiedot {
              json
              }
          }
          alaosanTeksti {
            json
          }
          alaosanTeksti2 {
            json
          }
        }
      }
      `}
      render={data => (
    <Layout>
      <GatsbySeo
      title='Uusi Nettikasino 2025 | Vain uudet kasinot esittelyssä!'
      description='Aina ajantasalla oleva lista uusista nettikasinoista. Katso vuoden 2025 parhaat uutuudet - ilman turhaa mainosp*skaa!'
   
   />
        <FAQJsonLd
            questions={[
              { question: 'Ovatko uudet nettikasinot turvallisia pelata?', answer: 'Varmistamme, että esittelemämme kasinot ovat lisenssoituja ja noudattavat turvallisuusstandardeja. On kuitenkin aina suositeltavaa lukea nettikasinon säännöt & ehdot ja arvioida operaattori itse ennen pelaamista.' },
              { question: 'Mitä maksutapoja uusilla kasinoilla on käytössä?', answer: 'Useimmat nettikasinot tarjoavat monenlaisia talletusmenetelmiä, kuten luottokortit, e-lompakot ja suomalaiset pankkisiirrot. Yksityiskohtaiset tiedot löytyvät kunkin kasinon maksusivulta.' },
              { question: 'Kuinka nopeasti saan voittoni uudelta nettikasinolta?', answer: 'Kotiutusaika vaihtelee nettikasinosta ja valitusta maksutavasta riippuen. Yleensä voit odottaa voittojen siirtoa 24 tunnin - 5 päivän sisällä.' },
              { question: 'Tarvitseeko minun verifioida asiakastilini ennen kuin voin kotiuttaa voittoja?', answer: 'Monet nettikasinot vaativat pelaajiltaan tilin vahvistuksen ennen ensimmäistä kotiutusta. Tämä sisältää usein henkilöllisyyden, osoitteen ja maksutavan vahvistamisen.' },
              { question: 'Onko uusilla kasinoilla rajoituksia pelaajien maantieteellisen sijainnin suhteen?', answer: 'Kyllä, jotkut kasinot eivät salli pelaajia tietyistä maista. On tärkeää tarkistaa kasinon ehdot tai ottaa yhteyttä asiakaspalveluun selvittääksesi, voitko pelata tietyssä kasinossa maastasi käsin. Kaikki sivuillamme esitellyt kasinot hyväksyvät asiakkaiksia suomalaiset pelaajat.' },
              { question: 'Miten tiedän, että uusi nettikasino toimii vastuullisen pelaamisen periaatteiden mukaisesti?', answer: 'Vastuullisesti toimivat kasinot tarjoavat usein tietoa vastuullisesta pelaamisesta sivuillaan ja työkaluja, kuten pelirajoituksia tai itse-estomahdollisuuksia. Voit myös tarkistaa, ovatko ne yhteistyössä järjestöjen kanssa, jotka edistävät vastuullista pelaamista.' },
            ]}
          />
        <Container>
        <div class="suodatin">
          <p><b>Vain uudet nettikasinot 2025 esittelyssä</b></p>
        <p><i>Tervetuloa uusinettikasino.com :lle! Meiltä löydät vain ja ainoastaan uusimmat, juuri markkinoille saapuneet kasinot, ei vanhaa roskaa! Selaa lista läpi, valitse uusi nettikasino ja aloita pelaaminen. Simppeliä.</i></p>
                    </div>
        <table>
          <tbody>
            {data.contentfulMoneysivujenOsiot.kasinolista.map(({logonTiedostonimi,kasinonListanimi,avausVuosi,lause1,ilmaiskierrokset,afflink,kasinonLisatiedot,rahansiirto,lisenssi}) => (
            <tr className={casinolistStyle.casino}>
							<td className={casinolistStyle.top}>
								<div className={casinolistStyle.logo}>
									<img data-src={`/images/a/${logonTiedostonimi}`} alt={kasinonListanimi} title={kasinonListanimi} className="lazyload" width="120" height="50"/>
									<div className={casinolistStyle.name}>
										<h5 className={casinolistStyle.title}>{kasinonListanimi}</h5>
                    <p className={casinolistStyle.vuosi}>Avattu: {avausVuosi}</p>
									</div>
								</div>
								<div className={casinolistStyle.content}>
									<div className={casinolistStyle.contentBox}>
										<div className={casinolistStyle.contentInfo}>
											<div className={casinolistStyle.offerItem}>
												<p>&#128073; {lause1}</p>
                        <p>&#128073; {ilmaiskierrokset}</p>
											</div>
										</div>
										<div className={casinolistStyle.contentBtn}>
											<a className={casinolistStyle.btn} href={afflink} target="_blank">Pelaa &#11153;</a>
                      <div className="talletus">{rahansiirto}</div>
                  	</div>
									</div>
								</div>
							</td>
							<td className={casinolistStyle.bottom}>

								<div className={casinolistStyle.casinoalaosa}>
                <div className="lisenssi">{lisenssi}</div>
                {documentToReactComponents(kasinonLisatiedot.json)}
								</div>
							</td>
						</tr>
            ))}
          </tbody>
        </table>
        <div className="ylaosa">{documentToReactComponents(data.contentfulMoneysivujenOsiot.ylosanTeksti.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
        })}</div>
        <PageTitle>{data.contentfulMoneysivujenOsiot.sivu}</PageTitle>
        <div className="alaosa">{documentToReactComponents(data.contentfulMoneysivujenOsiot.alaosanTeksti.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
      })}</div>
      <div className="alaosa2">{documentToReactComponents(data.contentfulMoneysivujenOsiot.alaosanTeksti2.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
      })}</div>
        <div class="suodatin3">
        <center><h2>Yleisimmät kysymykset ja vastaukset:</h2></center>
                <p>Alle on listattu muutamia yleisimpiä kysymyksiä nettikasinoista. Ongelmatilanteissa suosittelemme ensisijaisesti aina ottamaan yhteyttä kyseessä olevan kasinon asiakaspalveluun.</p>
                <div>
                  <div><h5>&#128204; Ovatko uudet nettikasinot turvallisia pelata? </h5></div>
                  <p>Varmistamme, että esittelemämme kasinot ovat lisenssoituja ja noudattavat turvallisuusstandardeja. On kuitenkin aina suositeltavaa lukea nettikasinon säännöt & ehdot ja arvioida operaattori itse ennen pelaamista.</p>
                </div>
                <div>
                  <div><h5>&#128204; Mitä maksutapoja uusilla kasinoilla on käytössä? </h5></div>
                  <p>Useimmat nettikasinot tarjoavat monenlaisia talletusmenetelmiä, kuten luottokortit, e-lompakot ja suomalaiset pankkisiirrot. Yksityiskohtaiset tiedot löytyvät kunkin kasinon maksusivulta.</p>
                </div>                
                <div>
                  <div><h5>&#128204; Kuinka nopeasti saan voittoni uudelta nettikasinolta?</h5></div>
                  <p>Kotiutusaika vaihtelee nettikasinosta ja valitusta maksutavasta riippuen. Yleensä voit odottaa voittojen siirtoa 24 tunnin - 5 päivän sisällä.</p>
                </div>     
                <div>
                  <div><h5>&#128204; Tarvitseeko minun verifioida asiakastilini ennen kuin voin kotiuttaa voittoja? </h5></div>
                  <p>Monet nettikasinot vaativat pelaajiltaan tilin vahvistuksen ennen ensimmäistä kotiutusta. Tämä sisältää usein henkilöllisyyden, osoitteen ja maksutavan vahvistamisen.</p>
                </div> 
                <div>
                  <div><h5>&#128204; Onko uusilla kasinoilla rajoituksia pelaajien maantieteellisen sijainnin suhteen? </h5></div>
                  <p>Kyllä, jotkut kasinot eivät salli pelaajia tietyistä maista. On tärkeää tarkistaa kasinon ehdot tai ottaa yhteyttä asiakaspalveluun selvittääksesi, voitko pelata tietyssä kasinossa maastasi käsin. Kaikki sivuillamme esitellyt kasinot hyväksyvät asiakkaiksia suomalaiset pelaajat.</p>
                </div> 
                <div>
                  <div><h5>&#128204; Miten tiedän, että uusi nettikasino toimii vastuullisen pelaamisen periaatteiden mukaisesti? </h5></div>
                  <p>Vastuullisesti toimivat kasinot tarjoavat usein tietoa vastuullisesta pelaamisesta sivuillaan ja työkaluja, kuten pelirajoituksia tai itse-estomahdollisuuksia. Voit myös tarkistaa, ovatko ne yhteistyössä järjestöjen kanssa, jotka edistävät vastuullista pelaamista.</p>
                </div>            
              </div>
      
      </Container>
    </Layout>
    )}
    />
  )
  }
}

export default Kasinot

